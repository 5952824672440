{
  "dropzone": {
    "default_message": "Drop files here or click to upload",
    "fallback_message": "Your browser does not support drag'n'drop file uploads.",
    "fallback_text": "Please use the fallback form below to upload your files like in the olden days.",
    "file_too_big": "File is too big ({{filesize}}MiB). Max filesize: {{maxFilesize}}MiB.",
    "invalid_file_type": "You can't upload files of this type.",
    "response_error": "Server responded with {{statusCode}} code.",
    "cancel_upload": "Cancel upload",
    "upload_canceled": "Upload canceled.",
    "cancel_upload_confirmation": "Are you sure you want to cancel this upload?",
    "remove_file": "Remove file",
    "max_files_exceeded": "You can't upload any more files.",
    "select_a_contract_type": "Please select a contract type.",
    "select_a_project": "Please select a project / department.",
    "not_enough_credits": "There are not enough credits to perform the analysis for all files."
  }
}
