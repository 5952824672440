import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "form" ]

  submit() {
    this.formTarget.requestSubmit()
  }

  submit_as_excel() {
    document.getElementById('export_format').value = 'xlsx';
    this.formTarget.setAttribute('data-turbo', 'false');
    this.formTarget.submit();
    this.formTarget.setAttribute('data-turbo', 'true');
    document.getElementById('export_format').value = 'html';
  }

  filter_with_delay(){
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.formTarget.requestSubmit()
    }, 200)
  }
}
