import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "picker"];


  showPicker() {
    this.pickerTarget.style.display = "block";
  }

  hidePicker() {
    this.pickerTarget.style.display = "none";
  }

  selectEmoji(event) {
    const emoji = event.detail.unicode;
    this.inputTarget.value = emoji; 
    this.hidePicker();
  }

  preventTyping(event) {
    event.preventDefault();
  }
}
