{
  "dropzone": {
    "default_message": "Dateien hier ablegen oder klicken, um hochzuladen",
    "fallback_message": "Ihr Browser unterstützt keine Drag'n'Drop Datei-Uploads.",
    "fallback_text": "Bitte verwenden Sie das untenstehende Formular zum Hochladen Ihrer Dateien wie in alten Zeiten.",
    "file_too_big": "Datei ist zu groß ({{filesize}}MiB). Maximale Dateigröße: {{maxFilesize}}MiB.",
    "invalid_file_type": "Sie können Dateien dieses Typs nicht hochladen.",
    "response_error": "Server antwortete mit dem Statuscode {{statusCode}}.",
    "cancel_upload": "Upload abbrechen",
    "upload_canceled": "Upload abgebrochen.",
    "cancel_upload_confirmation": "Sind Sie sicher, dass Sie diesen Upload abbrechen möchten?",
    "remove_file": "Datei entfernen",
    "max_files_exceeded": "Sie können keine weiteren Dateien hochladen.",
    "select_a_contract_type": "Bitte wählen Sie einen Vertragstyp aus.",
    "select_a_project": "Bitte wählen Sie ein Projekt / eine Abteilung aus.",
    "not_enough_credits": "Sie haben nicht genügend Guthaben um die Analyse für alle Dokumente durchzuführen."
  }
}
