import { Controller } from "@hotwired/stimulus";
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css';

export default class extends Controller {
  static targets = ["modal", "swiperContainer", "swiperSlide"];

  connect() {
    this.swiper = null; // Initialize Swiper instance as null
    this.keydownHandler = this.handleKeydown.bind(this); // Bind the keydown handler
  }

  open(event) {
    // Show the modal
    this.modalTarget.classList.add("active");
  
    // Add keydown event listener here
    document.addEventListener('keydown', this.keydownHandler);
  
    // Get the index of the clicked thumbnail
    const index = event.currentTarget.dataset.imageModalIndexValue;
  
    // Initialize Swiper if not already initialized
    if (!this.swiper) {
      this.swiper = new Swiper(this.swiperContainerTarget, {
        modules: [Navigation, Pagination],
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        slidesPerView: 1,       // Show only one slide at a time
        centeredSlides: true,   // Center the slide (optional)
        spaceBetween: 0,        // No space between slides
      });
    }
  
    // Slide to the clicked image
    this.swiper.slideTo(index, 0);
  }

  close(event) {
    // Close the modal if clicking outside the image or on the close button
    if (event.target === this.modalTarget || event.target.closest("button")) {
      this.modalTarget.classList.remove("active");

      // Remove the event listener for keydown
      document.removeEventListener("keydown", this.keydownHandler);
    }
  }

  handleKeydown(event) {
    switch (event.key) {
      case "Escape":
        this.modalTarget.classList.remove("active");
        document.removeEventListener("keydown", this.keydownHandler);
        break;
      case "ArrowRight":
        if (this.swiper) {
          this.swiper.slideNext();
        }
        break;
      case "ArrowLeft":
        if (this.swiper) {
          this.swiper.slidePrev();
        }
        break;
    }
  }

}
