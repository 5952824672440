import { Controller } from "@hotwired/stimulus";
import Dropzone from "dropzone";
import enTranslations from "../locales/en.json";
import deTranslations from "../locales/de.json";
import heic2any from "heic2any";

// Prevent Dropzone from auto-initializing
Dropzone.autoDiscover = false;

const translations = {
  en: enTranslations,
  de: deTranslations,
};

export default class extends Controller {
  static targets = ["input", "dropArea", "spinner"];

  connect() {
    this.csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");

    const locale = document.documentElement.lang || "en";
    this.translation = translations[locale] || translations["en"];

    // Dropzone configuration
    this.dropzone = new Dropzone(this.dropAreaTarget, {
      url: "/", // This won't matter as we are submitting the form
      headers: { "X-CSRF-Token": this.csrfToken },
      autoProcessQueue: false,
      uploadMultiple: true,
      addRemoveLinks: false,
      createImageThumbnails: true,
      previewsContainer: "#dropzone-previews",
      maxFiles: 100,
      previewTemplate: this.getPreviewTemplate(),
    });

    // Add files to form input for Rails processing
    this.dropzone.on("addedfile", (file) => {
      if (file.type === "image/heic" || file.name.endsWith(".heic")) {
        const previewElement = file.previewElement;
    
        // Add a spinner to the preview element
        if (previewElement) {
          previewElement.innerHTML = `
            <div class="spinner animate-spin w-5 h-5 border-4 border-t-transparent border-blue-500 rounded-full"></div>
          `; // Add your spinner HTML here
        }
    
        heic2any({ blob: file, toType: "image/jpeg" })
          .then((convertedBlob) => {
            const convertedFile = new File(
              [convertedBlob],
              file.name.replace(/\.heic$/, ".jpeg"),
              {
                type: "image/jpeg",
                lastModified: file.lastModified,
              }
            );
    
            // Replace the HEIC file with the converted file in Dropzone
            this.dropzone.removeFile(file);
            this.dropzone.addFile(convertedFile);
          })
          .catch((error) => {
            console.error("Error converting HEIC file:", error);
            this.dropzone.removeFile(file); // Remove invalid file
            alert("Could not preview HEIC file. Please use a different format.");
          });
      } else {
        this.addFileToForm(file); // Add the file to the form input
      }
    });

    this.dropzone.on("removedfile", (file) => {
      this.removeFileFromForm(file);
    });
  }

  getPreviewTemplate() {
    return `
      <div class="dz-preview dz-file-preview flex flex-col items-center justify-between">
        <img data-dz-thumbnail class="w-24 h-24 object-cover rounded mb-2" />
        <div class="dz-filename text-xs"><span data-dz-name></span></div>
        <div class="dz-error-message text-red-600 text-xs"><span data-dz-errormessage></span></div>
        <a class="dz-remove text-blue-500 text-xs" href="javascript:undefined;" data-dz-remove>
          ${this.translation.dropzone.remove_file}
        </a>
      </div>`;
  }

  addFileToForm(file) {
    const form = this.element.closest("form"); // Get the form element
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.name = "post[files][]"; // Matches the model's attachment field
    fileInput.classList.add("hidden");
  
    // Use DataTransfer to attach the file to the input
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(file);
    fileInput.files = dataTransfer.files;
  
    form.appendChild(fileInput); // Add the input to the form  
  }

  removeFileFromForm(file) {
    const inputs = this.element.querySelectorAll("input[type='file']");
    inputs.forEach((input) => {
      if (input.file === file) {
        input.remove();
      }
    });
  }

  submitForm(event) {
    event.preventDefault();

    // Trigger spinner
    if (this.hasSpinnerTarget) this.spinnerTarget.classList.remove("hidden");

    // Submit the form
    const form = this.element.closest("form");
    form.submit();
  }

  disconnect() {
    this.dropzone.destroy();
  }
}
